<script setup lang="ts">
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";

const props = defineProps<{ overflowHidden?: boolean; }>();
</script>

<template>
  <VWrapper
    :class="props.overflowHidden && 'overflow-hidden'"
    :indent="{ xs: '12px', s: '20px', m: '32px' }"
  >
    <slot />
  </VWrapper>
</template>

<style lang="postcss" scoped>
.overflow-hidden {
  overflow: hidden;
}
</style>
